import React, { Component } from "react"
import Button from "@mui/material/Button"
import "./Inner-cta.scss"
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIosRounded"
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIosRounded"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"

class InnerCTAScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <div className="visit-wrapper">
          <div className="blue-box on-left">
            <div className="inner-list-box">
              <h2 className="visit-header">
                Start your visit{" "}
                <span className="light">with our clinical team</span>
              </h2>
              <div className="service-list">
                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-video.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Telehealth Visit</p>
                    <p className="sub-text">Behavioral Health</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>

                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-pills.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Reduce drinking</p>
                    <p className="sub-text">Naloxone</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>

                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-pills.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Reduce Opioids</p>
                    <p className="sub-text">Suboxone</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="white-box on-right">
            <div className="inner-phone-box">
              <img
                className="phone-img"
                src={require("../assets/images/cta-phone.svg")}
                alt=""
              />
              <div className="input-wrap">
                <TextField
                  id="phone-input"
                  className="phone-input"
                  placeholder="Enter phone number"
                  margin="normal"
                  variant="standard"
                />
                <Button className="blue-simple-btn" color="primary">
                  Get App
                </Button>
              </div>
              <div className="store-btns">
                <Button className="itune-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                  >
                    <img
                      src={require("../assets/images/itunes.svg")}
                      alt="itune"
                    />
                  </a>
                </Button>
                <Button className="playstore-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en"
                  >
                    <img
                      src={require("../assets/images/playstore.png")}
                      alt="playstore"
                    />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="visit-wrapper">
          <div className="white-box on-left">
            <div className="inner-list-box">
              <h2 className="visit-header">
                Start your visit{" "}
                <span className="light">with our clinical team</span>
              </h2>
              <div className="service-list">
                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-video.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Telehealth Visit</p>
                    <p className="sub-text">Behavioral Health</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>

                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-pills.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Reduce drinking</p>
                    <p className="sub-text">Naloxone</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>

                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-pills.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Reduce Opioids</p>
                    <p className="sub-text">Suboxone</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="white-box on-right bg-img">
            <div className="inner-phone-box">
              <h2 className="visit-header">
                We’re here
                <span className="light">whenever you need us</span>
              </h2>
              <div className="input-wrap2">
                <TextField
                  id="phone-input"
                  className="phone-input"
                  placeholder="Enter phone number"
                  margin="normal"
                  variant="standard"
                />
                <Button className="blue-simple-btn" color="primary">
                  Get App
                </Button>
              </div>
              <div className="store-btns">
                <Button className="itune-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                  >
                    <img
                      src={require("../assets/images/itunes.svg")}
                      alt="itune"
                    />
                  </a>
                </Button>
                <Button className="playstore-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en"
                  >
                    <img
                      src={require("../assets/images/playstore.png")}
                      alt="playstore"
                    />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="start-today-wrapper">
          <div className="start-side">
            <div className="start-inner">
              <h2 className="start-main-text">Get Started Today:</h2>
              <div className="start-list">
                <div className="single-start">
                  <p>Behavioral Health</p>
                  <ArrowRightIcon />
                </div>
                <div className="single-start">
                  <p>Naloxone</p>
                  <ArrowRightIcon />
                </div>
                <div className="single-start">
                  <p>Suboxone</p>
                  <ArrowRightIcon />
                </div>
                <div className="single-start">
                  <p>Family Dynamics</p>
                  <ArrowRightIcon />
                </div>
                <div className="single-start">
                  <p>Grief</p>
                  <ArrowRightIcon />
                </div>
                <div className="single-start">
                  <p>Depression</p>
                  <ArrowRightIcon />
                </div>
              </div>
              <p className="start-now-text">
                We’re optimized for appointments within 24-48 hours.
              </p>
            </div>
          </div>
          <div className="pro-app-wrapper">
            <div className="pro-inner">
              <p className="pro-header">
                Providers are on the app after work hours too
              </p>
              <div className="input-wrap">
                <TextField
                  id="phone-input"
                  className="phone-input"
                  placeholder="Enter phone number"
                  margin="normal"
                  variant="standard"
                />
                <Button className="blue-simple-btn" color="primary">
                  Get App
                </Button>
              </div>
              <div className="store-btns">
                <Button className="itune-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                  >
                    <img
                      src={require("../assets/images/itunes.svg")}
                      alt="itune"
                    />
                  </a>
                </Button>
                <Button className="playstore-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en"
                  >
                    <img
                      src={require("../assets/images/playstore.png")}
                      alt="playstore"
                    />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="visit-wrapper">
          <div className="blue-box on-left">
            <div className="inner-list-box" style={{ width: 400 }}>
              <h2 className="visit-header">
                Book your appointment <span className="light">right here:</span>
              </h2>
              <FormControl variant="filled" className="service-dropdown">
                <InputLabel id="service-label">Select Service</InputLabel>
                <Select
                  labelId="service-label"
                  id="service-select"
                  value={10}
                  label="Select Service"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              <div className="slider-box">
                <Button className="left-arrow" color="primary">
                  <ArrowLeftIcon />
                </Button>
                <div className="slider-list">
                  <div className="single-date">
                    <p className="dark">05/02</p>
                    <p className="light">Monday</p>
                  </div>
                  <div className="single-date">
                    <p className="dark">05/02</p>
                    <p className="light">Monday</p>
                  </div>
                  <div className="single-date">
                    <p className="dark">05/02</p>
                    <p className="light">Monday</p>
                  </div>
                </div>
                <Button className="right-arrow" color="primary">
                  <ArrowRightIcon />
                </Button>
              </div>
              <div className="time-buttons">
                <Button className="single-time" color="primary">
                  Morning
                </Button>
                <Button className="single-time" color="primary">
                  Morning
                </Button>
                <Button className="single-time" color="primary">
                  Morning
                </Button>
                <Button className="single-time" color="primary">
                  Morning
                </Button>
                <Button className="single-time selected" color="primary">
                  Morning
                </Button>
                <Button className="single-time" color="primary">
                  Morning
                </Button>
                <Button className="single-time" color="primary">
                  Morning
                </Button>
                <Button className="single-time" color="primary">
                  Morning
                </Button>
                <Button className="single-time" color="primary">
                  Morning
                </Button>
              </div>
            </div>
          </div>
          <div className="white-box on-right">
            <div className="inner-phone-box">
              <h2 className="visit-header">
                Stop wasting time{" "}
                <span className="light">in waiting room.</span>
              </h2>
              <p className="visit-sub-text">
                Access all your care from our app.
              </p>
              <div className="input-wrap2">
                <TextField
                  id="phone-input"
                  className="phone-input"
                  placeholder="Enter phone number"
                  margin="normal"
                  variant="standard"
                />
                <Button className="blue-simple-btn" color="primary">
                  Get App
                </Button>
              </div>
              <div className="btns-with-img">
                <div className="store-btns">
                  <Button className="itune-btn" color="primary">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                    >
                      <img
                        src={require("../assets/images/itunes.svg")}
                        alt="itune"
                      />
                    </a>
                  </Button>
                  <Button className="playstore-btn" color="primary">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en"
                    >
                      <img
                        src={require("../assets/images/playstore.png")}
                        alt="playstore"
                      />
                    </a>
                  </Button>
                </div>
                <img
                  className="chair-img"
                  src={require("../assets/images/chair.png")}
                  alt="chair"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="visit-wrapper">
          <div className="gradient-box on-left">
            <div className="inner-list-box" style={{ width: 400 }}>
              <h2 className="visit-header" style={{ color: "#fff" }}>
                Get the services
                <span className="light">you want via telehealth</span>
              </h2>
              <p className="visit-sub-text" style={{ color: "#fff" }}>
                Same day & next day appointments available
              </p>
              <div className="cost-service-list">
                <div className="white-single-service">
                  <p className="dark">$75</p>
                  <p className="light">Telehealth Consultation</p>
                </div>
                <div className="white-single-service">
                  <p className="dark">$75</p>
                  <p className="light">Telehealth Consultation</p>
                </div>
                <div className="white-single-service">
                  <p className="dark">$75</p>
                  <p className="light">Telehealth Consultation</p>
                </div>
                <div className="white-single-service">
                  <p className="dark">$75</p>
                  <p className="light">Telehealth Consultation</p>
                </div>
              </div>
            </div>
          </div>
          <div className="white-box on-right bg-img-2">
            <div className="inner-phone-box">
              <h2 className="visit-header">
                No 12-step meetings
                <span className="light">or bad coffee required</span>
              </h2>
              <p className="visit-sub-text">Do everything from the app</p>
              <div className="input-wrap2">
                <TextField
                  id="phone-input"
                  className="phone-input"
                  placeholder="Enter phone number"
                  margin="normal"
                  variant="standard"
                />
                <Button className="blue-simple-btn" color="primary">
                  Get App
                </Button>
              </div>
              <div className="store-btns">
                <Button className="itune-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                  >
                    <img
                      src={require("../assets/images/itunes.svg")}
                      alt="itune"
                    />
                  </a>
                </Button>
                <Button className="playstore-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en"
                  >
                    <img
                      src={require("../assets/images/playstore.png")}
                      alt="playstore"
                    />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="visit-wrapper">
          <div className="white-box on-left">
            <div className="inner-list-box">
              <h2 className="visit-header">
                Get the services you need
                <span className="light"> while working from home</span>
              </h2>
              <p className="visit-sub-text">Or after the workday is over</p>
              <div className="service-list">
                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-video.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Telehealth Visit</p>
                    <p className="sub-text">Behavioral Health</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>

                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-pills.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Reduce drinking</p>
                    <p className="sub-text">Naloxone</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>

                <div className="single-service">
                  <img
                    className="service-img"
                    src={require("../assets/images/service-pills.svg")}
                    alt="Icon"
                  />
                  <div className="service-text">
                    <p className="main-text">Reduce Opioids</p>
                    <p className="sub-text">Suboxone</p>
                  </div>
                  <div className="next-arrow">
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="white-box on-right dark-bg-img">
            <div className="inner-phone-box">
              <h2 className="visit-header" style={{ color: "#fff" }}>
                Never get a surprise bill
                <span className="light">in the mail again</span>
              </h2>
              <p className="visit-sub-text" style={{ color: "#fff" }}>
                Transparent prices paid through our app
              </p>
              <div className="input-wrap2" style={{ borderWidth: 0 }}>
                <TextField
                  id="phone-input"
                  className="phone-input"
                  placeholder="Enter phone number"
                  margin="normal"
                  variant="standard"
                />
                <Button className="blue-simple-btn" color="primary">
                  Get App
                </Button>
              </div>
              <div className="store-btns">
                <Button className="itune-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                  >
                    <img
                      src={require("../assets/images/itunes.svg")}
                      alt="itune"
                    />
                  </a>
                </Button>
                <Button className="playstore-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en"
                  >
                    <img
                      src={require("../assets/images/playstore.png")}
                      alt="playstore"
                    />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="visit-wrapper">
          <div className="white-box on-left bg-img-3">
            <div className="inner-list-box">
              <h2 className="visit-header">
                Never listen
                <span className="light">
                  to other peoples «war stories» again
                </span>
              </h2>
              <p className="visit-sub-text">
                Get personalized care right in the app
              </p>
              <div className="store-btns">
                <Button className="itune-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                  >
                    <img
                      src={require("../assets/images/itunes.svg")}
                      alt="itune"
                    />
                  </a>
                </Button>
                <Button className="playstore-btn" color="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en"
                  >
                    <img
                      src={require("../assets/images/playstore.png")}
                      alt="playstore"
                    />
                  </a>
                </Button>
              </div>
            </div>
          </div>
          <div className="white-box on-right dark-bg-img-2">
            <div className="inner-phone-box" style={{ width: 400 }}>
              <h2 className="visit-header" style={{ color: "#fff" }}>
                Never have to park
                <span className="light">
                  in front of a treatment center again
                </span>
              </h2>
              <p className="visit-sub-text" style={{ color: "#fff" }}>
                Do everything from home on our app
              </p>
              <div className="input-wrap2" style={{ borderWidth: 0 }}>
                <TextField
                  id="phone-input"
                  className="phone-input"
                  placeholder="Enter phone number"
                  margin="normal"
                  variant="standard"
                />
                <Button className="blue-simple-btn" color="primary">
                  SMS me the link
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InnerCTAScreen
